































import { TrainingGroundViewmodel } from "../viewmodels/training-ground-viewmodel";
import { Observer } from "mobx-vue";
import { Component, Vue, Provide, Ref, Inject } from "vue-property-decorator";
import { StakeLockPeriods } from "@/constants/index";

@Observer
@Component({
  components: {
    SelectMonster: () => import("./select-monster.vue"),
    APRDetail: () => import("./APRDetail.vue"),
  },
})
export default class SelectLockPeriod extends Vue {
  @Inject() vm!: TrainingGroundViewmodel;
}
